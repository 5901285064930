
.gt-search-box {
    width: em(227);
    display: inline-block;
}

.gt-search-box-input {
    border: 1px solid #CED4DA !important;
    border-radius: 4px;
}

.table-main-search-input {
    padding: 0.75px 5px;
    border-radius: 5px;
    border: 1px solid #CED4DA;
}
.table-main-search-label{
    color: #767C81;
}