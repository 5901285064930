.left-img-container {
    background-image: url("../../../images/signin/home-page-image.jpg")  !important;
    // background-size: 20px 40px;
}

.limiter {
  width: 100%;
  margin: 0 auto;
}

.container-login100 {
  width: 100%;  
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  flex-wrap: wrap;
}


.wrap-login100 {
  width: 50%;
  min-height: 100vh;
  background: #fff;
  border-radius: 2px;
  position: relative;
}

/*------------------------------------------------------------------
[ Login100 more ]*/
.login100-more {
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
//   width: calc(100% - 520px);
    width: 50%;
  position: relative;
  z-index: 1;
}

.left-img-div{
    width: 95%;
    height: 55px;
}

.left-img-div > img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.left-img-inner-con > h1{
    font-size: 50px;
    line-height: 64px;
    color: #FFFFFF ;
    text-align: left;
    padding-left: 2.5rem;   
}
.left-img-inner-con > p{
    font-size: 24px;
    line-height: 30px;
    color: #FFFFFF ;
    text-align: left;
    
}
.right-side-con {
    margin-top: 7rem;
}
.right-side-con  > h3{
    font-size: 32px;
    line-height: 48px;
    color: #333333;
}

.sign-in-input-label {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #666666;
}

.sign-in-forgetpass{
    font-weight: 400;
    font-size: 16px !important;
    line-height: 24px;
    text-decoration-line: underline !important;
    color: #111111 !important;
}
.sign-in-btn > button{
    width: 50%;
    height: 64px;
    background: #0B4D89;
    border-radius: 10px;
    font-size: 20px !important;
}

@media (max-width: 992px) {
    .left-img-inner-con > h1 {
        font-size: 35px;
        line-height: 32px;
        padding-left: 1.5rem;
    }
    .left-img-inner-con > p {
        font-size: 20px;
        line-height: 25px;
    }
    .right-side-con {
        margin-top: 6rem;
    }
    .right-side-con > h3{
        font-size: 28px;
        line-height: 30px;
    }
    .form-label {
        font-size: 13px !important;
        line-height: 20px;
    }
    .signin-inputs {
        height: 45px;
        font-size: 14px;
        line-height: 20px;
    }
    .sign-in-forgetpass {
        font-size: 13px !important;
        line-height: 20px;
    }
    .sign-in-btn > button {
        width: 50%;
        height: 50px;
        font-size: 18px !important
    }
}
@media (max-width: 768px) {
    .left-img-div {
        width: 100%;
    }
    .left-img-inner-con > h1{
        font-size: 25px;
        line-height: 20px;
        padding-left: 1rem;
    }
    .left-img-inner-con > p {
        font-size: 16px;
        line-height: 20px;
    }
  }
@media (max-width: 576px) {
    .wrap-login100 {
        width: 100%;
        
    }
  }
