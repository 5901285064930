
.table-top-back-btn{
  font-weight: 400;
  text-transform: capitalize;
  color: #495057;
  display: inline-block;
  cursor: pointer;
}

.icon-img-con{
  width: 43px;
  height: 43px;
  background: #FFFFFF;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
  border-radius: 50px;
  display: inline-block;
}


.table-outer-heading {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #495057;
  display: inline-block;
}
.inventory-req-icon {
  padding: 10px;
}

.table-bottom-btn{
  padding: 7px 50px;
}
.table-action-btn{
  width: 162px;
  height: 22px;
  padding-top: 0px !important;
}

.view-detail-select{
  
background: #FFFFFF;
border: 1px solid #CED4DA;
border-radius: 4px;
}

.view-detail-select__control,
.status-header-search__control {
  // height: 30px;
  min-height: 30px !important;
}
.status-header-search__container {
  width: 70%;
}

.view-detail-select__placeholder {
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 18px;
color: #CED4DA;
}
.view-detail-select__indicator,
.status-header-search__indicator {
  color: #74788D !important;
  padding-left: 0px !important;
  padding-right: 2px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.status-header-search__indicator{
  color: #CED4DA !important;
}
.view-detail-select__indicators,
.status-header-search__indicators {
  min-width: inherit;
}
.view-detail-select__indicator-separator,
.status-header-search__indicator-separator {
  display: none;
}
.status-col-inv-req{
  min-width: 100px;
}
// :TODO: need to make these table styles to ut in data table and make them conditional bcs these tables need to be use 
// in many views

.view-detail-inv-req-table {
      border-top: 1px solid;
      // border-style: solid;
      border-color: #CDCDCD;
}
.view-detail-inv-req-table > table > thead{
  background: #F5F5F5;
  border-width: 1px 0px;
  border-style: solid;
  border-color: #CDCDCD;
}
.view-detail-inv-req-table > table > thead > tr > th{
  padding-top: 3px;
  padding-bottom: 3px;
  vertical-align: middle;
}
.view-detail-inv-req-p-ip{
  display: inline-block !important;
  width: 100px !important;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400 !important;
  font-size: 13px !important;
  line-height: 20px !important;
  color: #495057 !important;
  background: #FFFFFF;
  border: 1px solid #CED4DA !important;
  border-radius: 4px !important;
  padding: 0.3rem 0.5rem !important;
}

.view-detail-inv-req-p-ip-con  {
  width: fit-content;
  display: inline-block;
}
.view-detail-inv-req-p-ip-con > span {
  width: inherit !important;
}
// .custom-modal-size {
//     width: 90% !important;
// }
.inv-req-popup-heading{
font-weight: 600;
font-size: 16px;
line-height: 24px;
text-transform: uppercase;
}
.custom-btn-close-popup{
border: none;
  background: transparent;
}
.inv-req-popup-viewdetails .modal-content{
border-radius: 0px !important;
}
.gt-data-table table tbody tr:last-child td{
border-bottom: 1px solid #eff2f7;
}
.gt-data-table table tbody tr td:first-child,
.gt-data-table table thead tr th:first-child{
padding-left: 2rem;
}
.inv-req-popup-status{
  border-radius: 3px;
  padding: 2px 3px;
  line-height: 1;
  width: fit-content;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
}
.bg-orange{
background: #F1B44C;
height: fit-content;
}
.bg-green{
background: #34C38F;
height: fit-content;
}
.bg-blue{
background:#217BCD;
height: fit-content;
}
.bg-red{
background: #F46A6A;
height: fit-content;
}
.status-label{
font-size: 13px;
  line-height: 20px;
  color: #495057;
}
.status-search-con{
width: 250px;
}
.wh-ir-header-select-con {
width: 200px;
}
.ln-height-1 {
  line-height: 1 !important;
}

.department-req-items-table table tbody tr:last-child td {
  padding-bottom: 130px;
  border: none;
}

.inv-req-dpt-table {
  min-width: 525px;
}
.inv-req-pullback-table {
  min-width: 540px;
}
.inv-req-scrapreturn-table {
  min-width: 850px;
}
.inv-req-dpt-detail-table {
  min-width: 1200px;
}
.ir-warehouse-con{
  .ir-warehouse-search{
    padding: 6px 5px !important;
  }
}
@media (max-width: 1400px) {
  .wh-ir-header-select-con {
    width: 170px;
    }
}
@media (max-width: 1200px) {
  .wh-ir-header-select-con {
    width: 150px;
    }
}


