.pdfdownload-gray {
  filter: saturate(10%) brightness(200%) grayscale(100%);
  -webkit-filter:saturate(10%) brightness(200%) grayscale(100%);
}
.pdfdownload-icon {
  cursor: pointer;
}
.detail-view-table{
  min-width: 1200px;
}
