.scrap-return-button-container {
  // width: 100px;
  height: 35px;
}

.btn-common {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.image-upload-container {
  border: 1px solid #ced4da;
  height: 100%;
}
.container {
  height: 210px;
}
.div-container {
  display: flex;
  justify-content: right;
}
.imageHandle {
  object-fit: contain;
  height: 100%;
  width: 100%;
}
.image-preview-container{
  padding: 10px;
width: 200px;
height: 200px;

}

.image-preview{
width: 100%;
height: 100%;
object-fit: contain;

}