.casita-department-dashboard {
    // height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dashboard-boxes {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    max-width: 50%;
    margin: auto;
}

.dashboard-boxes a {
    margin: 0px 25px;
}

.height-scroll-table {
    overflow-y: auto;
    max-height: 50vh;
  }

@media (max-width: 1920px) {

  .dashboard-boxes { 
    max-width: 40% !important;
    margin: auto !important;
} 
}

@media (max-width: 1366px) {

    .dashboard-boxes { 
      max-width: 60% !important;
      margin: auto !important;
  } 
  }


  @media (max-width:870px) {
    .dashboard-boxes { 
        max-width: 100% !important;
        margin: auto !important;
    } 
  }

  

